<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section J <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section J: Homebuyer’s Own Contractor Disclosure
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12">
                              <p><strong>NO</strong> work can be conducted by the homebuyer’s own contractor if the homebuyer is building their new home under a contingency that their home has to sell.</p>
                              <br/>
                              <p>Seller/Builder would like to take a moment to explain our view on using your own subcontractors.
                                We have no problem with this if it assists you in building your new home with us.
                                We just need to explain that we feel totally confident with the selection of subcontractors that we have scheduled to build your home and that they will indeed complete their individual job to our quality, standards and allotted allowances.
                                This helps us control a more pleasing experience with building your home with Seller/Builder.</p>
                              <br/>
                                <p>If you DO choose to have an outside contractor work in your home, Seller/Builder will not be involved or responsible for any misrepresentations, pricing discrepancies, poor workmanship or Development Planning Committee disapproval’s.
                                  By using your own contractor, you may develop a situation that requires one of our contractors more work to now complete their job.
                                  (Example: Provide your own cabinetry in a box construction instead of our attached construction.) When this occurs, the homebuyer will be responsible for any of these additional costs that they have created.
                                  If Homebuyer wishes to have Boulder Contracting pay Outside Contractor’s invoice, this would be completed at the closing and arrangements would have to be made prior on how to handle costs.</p>
                              <br/>
                              <p>The above information also applies to work done by the homebuyer’s own contractor that conducts any partial work on the homes; heating, air conditioning, plumbing and electrical operations.
                                The homebuyer will be responsible for the costs incurred for any additional permits and/or inspections prior to closing on the property.</p>
                              <br/>
                              <p>In summary, we are comfortable with the people that we have selected to build your new home and hopefully this explains our concern of anything other than a pleasurable experience with us.</p>
                            </v-col>
                          </v-row>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Save
                        </v-btn>
                        </v-col>
                           </v-dialog>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.signiture_home_owner1"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.date1"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.signiture_home_owner2"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.date2"/>
                                </v-col>
                           <v-col cols="12" md="3">
                                    <v-text-field label="Seller/Builder"
                                    v-model="section.signiture_seller_builder"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.date3"/>
                                </v-col>
                         </v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                             </v-container>
                          </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: 'sectionF',
  data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      staff_email: 'steve@skelleymarketing.com',
      section_id: "J",
      add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      section: {
        home_owner: "",
        property_address: "",
        signiture_home_owner1: "",
        date1: "",
        signiture_home_owner2: "",
        date2: "",
        signiture_seller_builder: "",
        date3: ""
      },
    }
  },
  created () {
    this.getSection()
    this.getVendorEmail()
    },
    methods:{
      getSection(){
          axios
          .get(process.env.VUE_APP_GET_SECTION_URL,
          {
          params: {
              home_id: this.home_id,
              section_id: this.section_id
          }
          })
          .then(response => {
              if (response.data)
              {
                  var result = response.data
                  this.section = JSON.parse(result['JsonDetails'])
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getVendorEmail(){
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
          })
          .catch(error => {
          console.log(error)
          })
      },
      sectionComplete(){
        var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
        }

        axios
        .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
        .then(() => {
            this.alert = true
            if (this.add_vendor_email[0] === 'Y')
            this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
            })
            
        .catch(error => {
            console.log(error)
        })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
        },
      close(){
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
      },
      sendEmail(to,from,template,content,section){
        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }
        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions(){
        return {scope: localStorage.getItem("permissions")}
      }
    }
}
</script>